@tailwind base;
@tailwind components;
@tailwind utilities;
.card-bg {
  background-image: url("./components/assets/png/card-bg.png");
  background-position: center;
  background-size: 100% 100%;
}
.communituy-bg {
  background-image: url("./components/assets/png/button-bg.png");
  background-position: center;
  background-size: 100% 100%;
}
.footer-bg {
  background-image: url("./components/assets/png/footer-bg.png");
  background-size: cover;
  background-position: center;
}
.hero-bg {
  background-image: url("./components/assets/png/about-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
