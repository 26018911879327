body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #ffb000;
}

.heading {
  text-shadow: 6px 7px 3px #1a2b33;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: #000;
}
.sub-heading {
  -webkit-text-stroke-width: 1.7px;
  -webkit-text-stroke-color: #000;
}

.number {
  text-edge: cap;
  text-shadow: 4.251px 4.251px 0px #fff;
}

@media (max-width: 1024px) {
  .heading {
    text-shadow: 5px 6px 2px #1a2b33;
    -webkit-text-stroke-width: 2.5px;
  }
  .sub-heading {
    -webkit-text-stroke-width: 1.2px;
  }
}
@media (max-width: 768px) {
  .heading {
    text-shadow: 4px 5px 1px #1a2b33;
    -webkit-text-stroke-width: 2px;
  }
  .sub-heading {
    -webkit-text-stroke-width: 1px;
  }
}
@media (max-width: 640px) {
  .heading {
    text-shadow: 2.5px 3px 1px #1a2b33;
    -webkit-text-stroke-width: 1.3px;
  }
}
