@font-face {
  font-family: "Gliker Black Semi Condensed";
  src: url("./../public/Gliker/Gliker-BlackSemiCondensed.eot");
  src:
    local("Gliker Black Semi Condensed"),
    local("Gliker-BlackSemiCondensed"),
    url("./../public/Gliker/Gliker-BlackSemiCondensed.eot?#iefix")
      format("embedded-opentype"),
    url("./../public/Gliker/Gliker-BlackSemiCondensed.woff2") format("woff2"),
    url("./../public/Gliker/Gliker-BlackSemiCondensed.woff") format("woff"),
    url("./../public/Gliker/Gliker-BlackSemiCondensed.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gliker Black Extra Expanded";
  src: url("./../public/Gliker/Gliker-BlackExtraExpanded.eot");
  src:
    local("Gliker Black Extra Expanded"),
    local("Gliker-BlackExtraExpanded"),
    url("./../public/Gliker/Gliker-BlackExtraExpanded.eot?#iefix")
      format("embedded-opentype"),
    url("./../public/Gliker/Gliker-BlackExtraExpanded.woff2") format("woff2"),
    url("./../public/Gliker/Gliker-BlackExtraExpanded.woff") format("woff"),
    url("./../public/Gliker/Gliker-BlackExtraExpanded.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gliker Black Semi Expanded";
  src: url("./../public/Gliker/Gliker-BlackSemiExpanded.eot");
  src:
    local("Gliker Black Semi Expanded"),
    local("Gliker-BlackSemiExpanded"),
    url("./../public/Gliker/Gliker-BlackSemiExpanded.eot?#iefix")
      format("embedded-opentype"),
    url("./../public/Gliker/Gliker-BlackSemiExpanded.woff2") format("woff2"),
    url("./../public/Gliker/Gliker-BlackSemiExpanded.woff") format("woff"),
    url("./../public/Gliker/Gliker-BlackSemiExpanded.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gliker Bold Extra Condensed";
  src: url("./../public/Gliker/Gliker-BoldExtraCondensed.eot");
  src:
    local("Gliker Bold Extra Condensed"),
    local("Gliker-BoldExtraCondensed"),
    url("./../public/Gliker/Gliker-BoldExtraCondensed.eot?#iefix")
      format("embedded-opentype"),
    url("./../public/Gliker/Gliker-BoldExtraCondensed.woff2") format("woff2"),
    url("./../public/Gliker/Gliker-BoldExtraCondensed.woff") format("woff"),
    url("./../public/Gliker/Gliker-BoldExtraCondensed.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gliker Black Expanded";
  src: url("./../public/Gliker/Gliker-BlackExpanded.eot");
  src:
    local("Gliker Black Expanded"),
    local("Gliker-BlackExpanded"),
    url("./../public/Gliker/Gliker-BlackExpanded.eot?#iefix")
      format("embedded-opentype"),
    url("./../public/Gliker/Gliker-BlackExpanded.woff2") format("woff2"),
    url("./../public/Gliker/Gliker-BlackExpanded.woff") format("woff"),
    url("./../public/Gliker/Gliker-BlackExpanded.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gliker Bold Expanded";
  src: url("./../public/Gliker/Gliker-BoldExpanded.eot");
  src:
    local("Gliker Bold Expanded"),
    local("Gliker-BoldExpanded"),
    url("./../public/Gliker/Gliker-BoldExpanded.eot?#iefix")
      format("embedded-opentype"),
    url("./../public/Gliker/Gliker-BoldExpanded.woff2") format("woff2"),
    url("./../public/Gliker/Gliker-BoldExpanded.woff") format("woff"),
    url("./../public/Gliker/Gliker-BoldExpanded.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gliker";
  src: url("./../public/Gliker/Gliker-Bold.eot");
  src:
    local("Gliker Bold"),
    local("Gliker-Bold"),
    url("./../public/Gliker/Gliker-Bold.eot?#iefix") format("embedded-opentype"),
    url("./../public/Gliker/Gliker-Bold.woff2") format("woff2"),
    url("./../public/Gliker/Gliker-Bold.woff") format("woff"),
    url("./../public/Gliker/Gliker-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gliker Bold SemiCondensed";
  src: url("./../public/Gliker/Gliker-BoldSemiCondensed.eot");
  src:
    local("Gliker Bold SemiCondensed"),
    local("Gliker-BoldSemiCondensed"),
    url("./../public/Gliker/Gliker-BoldSemiCondensed.eot?#iefix")
      format("embedded-opentype"),
    url("./../public/Gliker/Gliker-BoldSemiCondensed.woff2") format("woff2"),
    url("./../public/Gliker/Gliker-BoldSemiCondensed.woff") format("woff"),
    url("./../public/Gliker/Gliker-BoldSemiCondensed.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gliker Black Extra Condensed";
  src: url("./../public/Gliker/Gliker-BlackExtraCondensed.eot");
  src:
    local("Gliker Black Extra Condensed"),
    local("Gliker-BlackExtraCondensed"),
    url("./../public/Gliker/Gliker-BlackExtraCondensed.eot?#iefix")
      format("embedded-opentype"),
    url("./../public/Gliker/Gliker-BlackExtraCondensed.woff2") format("woff2"),
    url("./../public/Gliker/Gliker-BlackExtraCondensed.woff") format("woff"),
    url("./../public/Gliker/Gliker-BlackExtraCondensed.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gliker";
  src: url("./../public/Gliker/Gliker-Black.eot");
  src:
    local("Gliker Black"),
    local("Gliker-Black"),
    url("./../public/Gliker/Gliker-Black.eot?#iefix")
      format("embedded-opentype"),
    url("./../public/Gliker/Gliker-Black.woff2") format("woff2"),
    url("./../public/Gliker/Gliker-Black.woff") format("woff"),
    url("./../public/Gliker/Gliker-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gliker Bold Condensed";
  src: url("./../public/Gliker/Gliker-BoldCondensed.eot");
  src:
    local("Gliker Bold Condensed"),
    local("Gliker-BoldCondensed"),
    url("./../public/Gliker/Gliker-BoldCondensed.eot?#iefix")
      format("embedded-opentype"),
    url("./../public/Gliker/Gliker-BoldCondensed.woff2") format("woff2"),
    url("./../public/Gliker/Gliker-BoldCondensed.woff") format("woff"),
    url("./../public/Gliker/Gliker-BoldCondensed.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gliker Black Condensed";
  src: url("./../public/Gliker/Gliker-BlackCondensed.eot");
  src:
    local("Gliker Black Condensed"),
    local("Gliker-BlackCondensed"),
    url("./../public/Gliker/Gliker-BlackCondensed.eot?#iefix")
      format("embedded-opentype"),
    url("./../public/Gliker/Gliker-BlackCondensed.woff2") format("woff2"),
    url("./../public/Gliker/Gliker-BlackCondensed.woff") format("woff"),
    url("./../public/Gliker/Gliker-BlackCondensed.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gliker Bold Extra Expanded";
  src: url("./../public/Gliker/Gliker-BoldExtraExpanded.eot");
  src:
    local("Gliker Bold Extra Expanded"),
    local("Gliker-BoldExtraExpanded"),
    url("./../public/Gliker/Gliker-BoldExtraExpanded.eot?#iefix")
      format("embedded-opentype"),
    url("./../public/Gliker/Gliker-BoldExtraExpanded.woff2") format("woff2"),
    url("./../public/Gliker/Gliker-BoldExtraExpanded.woff") format("woff"),
    url("./../public/Gliker/Gliker-BoldExtraExpanded.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gliker Condensed";
  src: url("./../public/Gliker/Gliker-RegularCondensed.eot");
  src:
    local("Gliker Regular Condensed"),
    local("Gliker-RegularCondensed"),
    url("./../public/Gliker/Gliker-RegularCondensed.eot?#iefix")
      format("embedded-opentype"),
    url("./../public/Gliker/Gliker-RegularCondensed.woff2") format("woff2"),
    url("./../public/Gliker/Gliker-RegularCondensed.woff") format("woff"),
    url("./../public/Gliker/Gliker-RegularCondensed.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gliker Semi Condensed";
  src: url("./../public/Gliker/Gliker-RegularSemiCondensed.eot");
  src:
    local("Gliker Regular Semi Condensed"),
    local("Gliker-RegularSemiCondensed"),
    url("./../public/Gliker/Gliker-RegularSemiCondensed.eot?#iefix")
      format("embedded-opentype"),
    url("./../public/Gliker/Gliker-RegularSemiCondensed.woff2") format("woff2"),
    url("./../public/Gliker/Gliker-RegularSemiCondensed.woff") format("woff"),
    url("./../public/Gliker/Gliker-RegularSemiCondensed.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gliker Extra Condensed";
  src: url("./../public/Gliker/Gliker-RegularExtraCondensed.eot");
  src:
    local("Gliker Regular Extra Condensed"),
    local("Gliker-RegularExtraCondensed"),
    url("./../public/Gliker/Gliker-RegularExtraCondensed.eot?#iefix")
      format("embedded-opentype"),
    url("./../public/Gliker/Gliker-RegularExtraCondensed.woff2") format("woff2"),
    url("./../public/Gliker/Gliker-RegularExtraCondensed.woff") format("woff"),
    url("./../public/Gliker/Gliker-RegularExtraCondensed.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gliker SemiBold Extra Condensed";
  src: url("./../public/Gliker/Gliker-SemiBoldExtraCondensed.eot");
  src:
    local("Gliker SemiBold Extra Condensed"),
    local("Gliker-SemiBoldExtraCondensed"),
    url("./../public/Gliker/Gliker-SemiBoldExtraCondensed.eot?#iefix")
      format("embedded-opentype"),
    url("./../public/Gliker/Gliker-SemiBoldExtraCondensed.woff2")
      format("woff2"),
    url("./../public/Gliker/Gliker-SemiBoldExtraCondensed.woff") format("woff"),
    url("./../public/Gliker/Gliker-SemiBoldExtraCondensed.ttf")
      format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gliker SemiBold Semi Expanded";
  src: url("./../public/Gliker/Gliker-SemiBoldSemiExpanded.eot");
  src:
    local("Gliker SemiBold Semi Expanded"),
    local("Gliker-SemiBoldSemiExpanded"),
    url("./../public/Gliker/Gliker-SemiBoldSemiExpanded.eot?#iefix")
      format("embedded-opentype"),
    url("./../public/Gliker/Gliker-SemiBoldSemiExpanded.woff2") format("woff2"),
    url("./../public/Gliker/Gliker-SemiBoldSemiExpanded.woff") format("woff"),
    url("./../public/Gliker/Gliker-SemiBoldSemiExpanded.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gliker SemiBold Semi Condensed";
  src: url("./../public/Gliker/Gliker-SemiBoldSemiCondensed.eot");
  src:
    local("Gliker SemiBold Semi Condensed"),
    local("Gliker-SemiBoldSemiCondensed"),
    url("./../public/Gliker/Gliker-SemiBoldSemiCondensed.eot?#iefix")
      format("embedded-opentype"),
    url("./../public/Gliker/Gliker-SemiBoldSemiCondensed.woff2") format("woff2"),
    url("./../public/Gliker/Gliker-SemiBoldSemiCondensed.woff") format("woff"),
    url("./../public/Gliker/Gliker-SemiBoldSemiCondensed.ttf")
      format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gliker Expanded";
  src: url("./../public/Gliker/Gliker-RegularExpanded.eot");
  src:
    local("Gliker Regular Expanded"),
    local("Gliker-RegularExpanded"),
    url("./../public/Gliker/Gliker-RegularExpanded.eot?#iefix")
      format("embedded-opentype"),
    url("./../public/Gliker/Gliker-RegularExpanded.woff2") format("woff2"),
    url("./../public/Gliker/Gliker-RegularExpanded.woff") format("woff"),
    url("./../public/Gliker/Gliker-RegularExpanded.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gliker SemiBold Condensed";
  src: url("./../public/Gliker/Gliker-SemiBoldCondensed.eot");
  src:
    local("Gliker SemiBold Condensed"),
    local("Gliker-SemiBoldCondensed"),
    url("./../public/Gliker/Gliker-SemiBoldCondensed.eot?#iefix")
      format("embedded-opentype"),
    url("./../public/Gliker/Gliker-SemiBoldCondensed.woff2") format("woff2"),
    url("./../public/Gliker/Gliker-SemiBoldCondensed.woff") format("woff"),
    url("./../public/Gliker/Gliker-SemiBoldCondensed.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gliker Extra Expanded";
  src: url("./../public/Gliker/Gliker-RegularExtraExpanded.eot");
  src:
    local("Gliker Regular Extra Expanded"),
    local("Gliker-RegularExtraExpanded"),
    url("./../public/Gliker/Gliker-RegularExtraExpanded.eot?#iefix")
      format("embedded-opentype"),
    url("./../public/Gliker/Gliker-RegularExtraExpanded.woff2") format("woff2"),
    url("./../public/Gliker/Gliker-RegularExtraExpanded.woff") format("woff"),
    url("./../public/Gliker/Gliker-RegularExtraExpanded.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gliker";
  src: url("./../public/Gliker/Gliker-SemiBold.eot");
  src:
    local("Gliker SemiBold"),
    local("Gliker-SemiBold"),
    url("./../public/Gliker/Gliker-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("./../public/Gliker/Gliker-SemiBold.woff2") format("woff2"),
    url("./../public/Gliker/Gliker-SemiBold.woff") format("woff"),
    url("./../public/Gliker/Gliker-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gliker SemiBold Extra Expanded";
  src: url("./../public/Gliker/Gliker-SemiBoldExtraExpanded.eot");
  src:
    local("Gliker SemiBold Extra Expanded"),
    local("Gliker-SemiBoldExtraExpanded"),
    url("./../public/Gliker/Gliker-SemiBoldExtraExpanded.eot?#iefix")
      format("embedded-opentype"),
    url("./../public/Gliker/Gliker-SemiBoldExtraExpanded.woff2") format("woff2"),
    url("./../public/Gliker/Gliker-SemiBoldExtraExpanded.woff") format("woff"),
    url("./../public/Gliker/Gliker-SemiBoldExtraExpanded.ttf")
      format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gliker";
  src: url("./../public/Gliker/Gliker-Regular.eot");
  src:
    local("Gliker Regular"),
    local("Gliker-Regular"),
    url("./../public/Gliker/Gliker-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./../public/Gliker/Gliker-Regular.woff2") format("woff2"),
    url("./../public/Gliker/Gliker-Regular.woff") format("woff"),
    url("./../public/Gliker/Gliker-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gliker Bold Semi Expanded";
  src: url("./../public/Gliker/Gliker-BoldSemiExpanded.eot");
  src:
    local("Gliker Bold Semi Expanded"),
    local("Gliker-BoldSemiExpanded"),
    url("./../public/Gliker/Gliker-BoldSemiExpanded.eot?#iefix")
      format("embedded-opentype"),
    url("./../public/Gliker/Gliker-BoldSemiExpanded.woff2") format("woff2"),
    url("./../public/Gliker/Gliker-BoldSemiExpanded.woff") format("woff"),
    url("./../public/Gliker/Gliker-BoldSemiExpanded.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gliker SemiBold Expanded";
  src: url("./../public/Gliker/Gliker-SemiBoldExpanded.eot");
  src:
    local("Gliker SemiBold Expanded"),
    local("Gliker-SemiBoldExpanded"),
    url("./../public/Gliker/Gliker-SemiBoldExpanded.eot?#iefix")
      format("embedded-opentype"),
    url("./../public/Gliker/Gliker-SemiBoldExpanded.woff2") format("woff2"),
    url("./../public/Gliker/Gliker-SemiBoldExpanded.woff") format("woff"),
    url("./../public/Gliker/Gliker-SemiBoldExpanded.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gliker Semi Expanded";
  src: url("./../public/Gliker/Gliker-RegularSemiExpanded.eot");
  src:
    local("Gliker Regular Semi Expanded"),
    local("Gliker-RegularSemiExpanded"),
    url("./../public/Gliker/Gliker-RegularSemiExpanded.eot?#iefix")
      format("embedded-opentype"),
    url("./../public/Gliker/Gliker-RegularSemiExpanded.woff2") format("woff2"),
    url("./../public/Gliker/Gliker-RegularSemiExpanded.woff") format("woff"),
    url("./../public/Gliker/Gliker-RegularSemiExpanded.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "super bloom";
  src: url("./../public/super_bloom/Super\ Bloom\ Personal\ Use.ttf");
}

.font-super {
  font-family: "super bloom";
}
.font-gliker-normal-2 {
  font-family: "Gliker Extra Expanded";
}
.font-gliker-noraml {
  font-family: "Gliker Semi Expanded";
}
.font-gliker-medium {
  font-family: "Gliker Semi Expanded";
}
.font-gliker-semi-bold {
  font-family: "Gliker SemiBold Expanded";
}
.font-gliker-bold {
  font-family: "Gliker Bold Semi Expanded";
}
.font-gliker-black {
  font-family: "Gliker Black Semi Condensed";
}
